import * as React from "react"
import Template from "../components/Template"
import SEO from "../components/SEO"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import {FaFilter} from "@react-icons/all-files/fa/FaFilter"
import Collapse from "react-bootstrap/Collapse"
import SearchPropiedad from "../components/Search/SearchPropiedad"
import ReactPaginate from "react-paginate"
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
const BuscarPropiedad = (props) => {
 
//recepción de datos de consulta graphql
const datos=props.data.allSanityPropiedad.nodes

//parametros url
const [paramsModalidad, setParamsModalidad] = useQueryParam("modalidad", StringParam);
const [paramsMainBusqueda, setParamsMainBusqueda] =useQueryParam("busqueda", StringParam)
const [paramFilters, setParamFilters] =useQueryParam("filters", StringParam)
const [paramsTipo, setParamsTipo]=useQueryParam("tipo", StringParam)
const doInitialSearch=paramsMainBusqueda || paramsModalidad || paramsTipo ? true : false
const [initialSearch, setInitialSearch]=React.useState(doInitialSearch)
let searchLabel="Propiedades"
let searchParagraph=props.data.sanityGlobalConfig.textPropiedades
if(paramsModalidad && paramsModalidad==="Arriendo"){
  searchLabel="Arriendos"
  searchParagraph=props.data.sanityGlobalConfig.textArriendos
}
if(paramsTipo && paramsTipo==="Terreno"){
  searchLabel="Terrenos"
  searchParagraph=props.data.sanityGlobalConfig.textTerrenos
}
//estados de formulario
const [mainBusqueda, setMainBusqueda]=React.useState(paramsMainBusqueda? paramsMainBusqueda : "")
const [modalidad, setModalidad]=React.useState(paramsModalidad? paramsModalidad : "")
const [ubicacion, setUbicacion]=React.useState("")
const [precioMinimo, setPrecioMinimo]=React.useState("")
const [precioMaximo, setPrecioMaximo]=React.useState("")
const [tipo, setTipo]=React.useState(paramsTipo? paramsTipo : "")
const [banos, setBanos]= React.useState("")
const [dormitorios, setDormitorios]=React.useState("")
const [superficieTerreno, setSuperficieTerreno]=React.useState("")
const [superficieConstruccion, setSuperficieConstruccion]=React.useState("")

//hooks de parametros
React.useEffect(()=>{
  setInitialSearch(true)
  if (!paramsMainBusqueda) setMainBusqueda("")
  if (!paramsTipo) setTipo("")
  setUbicacion("")
  setPrecioMinimo("")
  setPrecioMaximo("")
  setBanos("")
  setDormitorios("")
  setSuperficieTerreno("")
  setSuperficieConstruccion("")
  setModalidad(paramsModalidad? paramsModalidad : "")
},[paramsModalidad])

React.useEffect(()=>{
  setInitialSearch(true)
  if (!paramsMainBusqueda) setMainBusqueda("")
  if (!paramsModalidad) setModalidad("")
  setUbicacion("")
  setPrecioMinimo("")
  setPrecioMaximo("")
  setBanos("")
  setDormitorios("")
  setSuperficieTerreno("")
  setSuperficieConstruccion("")
  setTipo(paramsTipo? paramsTipo : "")
},[paramsTipo])

React.useEffect(()=>{
  if (initialSearch){
    setInitialSearch(false)
    searchFunction()
  }
}, [modalidad])

React.useEffect(()=>{
  if (initialSearch){
    setInitialSearch(false)
    searchFunction()
  }
}, [tipo])

React.useEffect(()=>{
  if(paramsMainBusqueda!==""){
    setInitialSearch(false)
    searchFunction()
  }
},[])

//estados
const [currentPage, setCurrentPage] = React.useState(0); //página actual del paginador
const [open, setOpen] = React.useState(paramFilters==="true"); //abrir filtros
const [valorUF, setValorUF] = React.useState(999999)



const [consulta, setConsulta] = React.useState({
  mainBusqueda: "",
  ubicacion:"",
  modalidad: "",
  filteredData: [],
  precioMinimo:"",
  precioMaximo:"",
  tipo:"",
  banos:"",
  dormitorios:"",
  superficieTerreno: "",
  superficieConstruccion: ""
})

//configuracion del paginador y  buscador

  
  const hasSearchResults = consulta.filteredData && (consulta.mainBusqueda!=="" || consulta.ubicacion!=="" || consulta.modalidad!=="" || consulta.precioMinimo!==""|| consulta.precioMaximo!==""|| consulta.tipo!=="" || consulta.banos!=="" || consulta.dormitorios!=="" || consulta.superficieTerreno!=="" || consulta.superficieConstruccion!=="")
  const propiedades = hasSearchResults ? consulta.filteredData : datos
  const PER_PAGE = 6;
    const offset = currentPage * PER_PAGE;
    const currentPageData = propiedades
        .slice(offset, offset + PER_PAGE)
        .map((item) => {
            return (
                <Col md={4} sm={12}>
                    <SearchPropiedad propiedad={item}/>
                </Col>
            )})
    const pageCount= Math.ceil(propiedades.length / PER_PAGE); 
  
 

//obtención del valor de la UF de forma asíncrona
const getUFValue=async ()=>{
    fetch('https://mindicador.cl/api')
        .then(response => response.json())
        .then(data => setValorUF(data.uf.valor));
}
getUFValue()

//handlers
const handlePageClick=({ selected: selectedPage })=> {
    setCurrentPage(selectedPage);
}

const handleChangeSearch=(e)=>{
  const input=e.target.value
  const name=e.target.name
  switch(name){
    case "modalidad": setModalidad(input)
    break;
    case "mainBusqueda": setMainBusqueda(input)
    break;
    case "ubicacion": setUbicacion(input)
    break;
    case "precioMinimo": setPrecioMinimo(input)
    break;
    case "precioMaximo": setPrecioMaximo(input)
    break;
    case "tipo": setTipo(input)
    break;
    case "banos": setBanos(input)
    break;
    case "dormitorios": setDormitorios(input)
    break;
    case "superficieTerreno": setSuperficieTerreno(input)
    break;
    case "superficieConstruccion": setSuperficieConstruccion(input)
    break;
  }
}

const searchFunction=(event,newModalidad)=>{
  let filteredData=datos
  if (mainBusqueda.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
      const { descripcion, title, caracteristicasInternas,caracteristicasExternas } = propiedad
      let textoSegmented=[]
      descripcion.forEach(child=>{
          child.children.forEach(segmento=>{
              textoSegmented.push(segmento.text)
          })
         
      })
      const texto=textoSegmented.join("")
      
        return (
          
          texto.toLowerCase().includes(mainBusqueda.toLowerCase()) ||
          title.toLowerCase().includes(mainBusqueda.toLowerCase()) ||
          (caracteristicasInternas &&
            caracteristicasInternas
              .join("")
              .toLowerCase()
              .includes(mainBusqueda.toLowerCase())) ||
              (caracteristicasExternas &&
                caracteristicasExternas
                  .join("")
                  .toLowerCase()
                  .includes(mainBusqueda.toLowerCase()))
        )
    })
  }


  if (ubicacion.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
      
        return (
          propiedad.ciudad.toLowerCase().includes(ubicacion.toLowerCase()) ||
          propiedad.region.toLowerCase().includes(ubicacion.toLowerCase()) ||
          (propiedad.zona &&
            propiedad.zona
              .toLowerCase()
              .includes(ubicacion.toLowerCase()))
        )
    })
  }
  const buscarModalidad=modalidad
  if (buscarModalidad!==""){
    filteredData = filteredData.filter(propiedad=> {
      
        return (
          propiedad.modalidad.toLowerCase().includes(buscarModalidad.toLowerCase())
        )
    })
  }
  if (tipo.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
        return (
          (propiedad.tipo &&
            propiedad.tipo.name
              .toLowerCase()
              .includes(tipo.toLowerCase()))
        )
    })
  }

  if (precioMinimo.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
        let precioOriginal=propiedad.precio
        if (propiedad.unidad==="UF"){
          precioOriginal=precioOriginal*valorUF
        }
        return (
          (precioOriginal>=precioMinimo)
        )
    })
  }
  if (precioMaximo.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
        let precioOriginal=propiedad.precio
        if (propiedad.unidad==="UF"){
          precioOriginal=precioOriginal*valorUF
        }
        return (
          (precioOriginal<=precioMaximo)
        )
    })
  }

  if (banos.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
        return (
          (propiedad.banos==banos)
        )
    })
  }

  if (dormitorios.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
        return (
          (propiedad.dormitorios==dormitorios)
        )
    })
  }

  if (superficieTerreno.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
      let diff = Math.abs( superficieTerreno - propiedad.superficieTerreno );
      
        return (
          (diff<50 && diff >-50)
        )
    })
  }

  if (superficieConstruccion.trim()!==""){
    filteredData = filteredData.filter(propiedad=> {
      let diff = Math.abs( superficieConstruccion - propiedad.superficieConstruccion);
      
        return (
          (diff<50 && diff >-50)
        )
    })
  }

  setCurrentPage(0)
  setConsulta({
    mainBusqueda,
    ubicacion,
    modalidad,
    precioMinimo,
    precioMaximo,
    tipo,
    banos,
    dormitorios,
    superficieTerreno,
    superficieConstruccion,
    filteredData, 
  })
}

  return (
    <Template>
      <SEO title="Buscar propiedad"/>
      <Container style={{marginTop: "2em"}}>
        <h1 style={{textAlign: "center"}}>{searchLabel}</h1>
        <p style={{textAlign:"center", marginTop:"1em", marginBottom:"1em"}} className="noSmall">{searchParagraph}</p>
        <Container style={{
            backgroundColor: "var(--primary)", 
            color: "var(--brightText)",
            paddingTop: "2em",
            paddingBottom: "2em",
            paddingLeft: "2em",
            paddingRight: "2em",
            marginTop: "2em"
        }}>
            <Form>
                <Row>
                    <Col>
                    <InputGroup className="mb-3">
                            <FormControl
                                className="searchnput"
                                placeholder="Ingrese su busqueda" name="mainBusqueda" onChange={handleChangeSearch} value={mainBusqueda}/>
                            <InputGroup.Append>
                            <Button variant="outline-secondary" className="searchMainButton" onClick={searchFunction}><FaSearch /></Button>
                            
                                <Button 
                                    variant="outline-secondary" 
                                    className="searchFilterButton" 
                                    onClick={() => setOpen(!open)}
                                    aria-controls="filtros"
                                    aria-expanded={open}>
                                        <FaFilter />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                <Collapse in={open}>
                    <div id="filtros">
                        <Row>
                            <Col md={3} sm={12}>
                                <Form.Control name="ubicacion" value={ubicacion} onChange={handleChangeSearch} className="filtroControl" placeholder="Ubicación" />
                            </Col>
                            <Col md={3} sm={12}>
                              <Form.Control class="filtroControl" name="tipo" onChange={handleChangeSearch}  as="select" value={tipo}>
                                <option value="">Cualquier tipo</option>
                                {props.data.allSanityTipo.nodes.map(item=>(
                                  <option value={item.name}>{item.name}</option>
                                ))}
                              </Form.Control>
                            </Col>
                            <Col md={2} sm={12}>
                            <Form.Control class="filtroControl" name="modalidad" onChange={handleChangeSearch}  as="select" value={modalidad}>
                              <option value="">Venta y Arriendo</option>
                              <option value="Venta">Solo venta</option>
                              <option value="Arriendo">Solo arriendo</option>
                            </Form.Control>
                            </Col>
                            <Col md={2} sm={12}>
                            <Form.Control type="number" onChange={handleChangeSearch} value={precioMinimo} name="precioMinimo" className="filtroControl" placeholder="Precio mínimo (CLP)" />
                            </Col>
                            <Col md={2} sm={12}>
                            <Form.Control type="number" onChange={handleChangeSearch} value={precioMaximo} name="precioMaximo" className="filtroControl" placeholder="Precio máximo (CLP)" />
                            </Col>
                         
                        </Row>
                        <Row>
                          
                            <Col md={3} sm={12}>
                                <Form.Control type="number" onChange={handleChangeSearch} value={banos} name="banos" className="filtroControl" placeholder="Baños" />
                            </Col>
                            <Col md={3} sm={12}>
                                <Form.Control type="number" onChange={handleChangeSearch} value={dormitorios} name="dormitorios" className="filtroControl" placeholder="Dormitorios" />
                            </Col>
                            <Col md={3} sm={12}>
                            <Form.Control type="number" onChange={handleChangeSearch} value={superficieTerreno} name="superficieTerreno" className="filtroControl" placeholder="Superficie terreno (M2)" />
                            </Col>
                            <Col md={3} sm={12}>
                                <Form.Control type="number" onChange={handleChangeSearch} value={superficieConstruccion} name="superficieConstruccion" className="filtroControl" placeholder="Superficie construcción (M2)" />
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </Form>
        </Container>
      </Container>
      <Container className="rowSearch" style={{marginTop: "2em"}}>
        <Row className="justify-content-center">
            {currentPageData}
                        
         </Row>
        <Row className="justify-content-center">
            <Col xs={8} style={{textAlign: "center"}}>
                <ReactPaginate
                previousLabel={"← Anterior"}
                nextLabel={"Siguiente →"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
                />
            </Col>
      </Row>
      </Container>
    </Template>
  )
}


export default BuscarPropiedad

export const query = graphql`
  query{
    allSanityTipo {
      nodes{
        name
      }
    }
    sanityGlobalConfig{
      textArriendos
      textPropiedades
      textTerrenos
    }
    allSanityPropiedad(
      filter:{
        activo:{ 
          eq: true
        }
      }
      sort:{
        fields: [fechaPublicacion]
        order: DESC
      }
    ){
      nodes{
        title
        unidad
        precio
        fechaPublicacion
        zona
        ciudad
        region
        modalidad
        slug{
            current
        }
        descripcion{
          children{
            text
          }
        }
        image{
          asset{
            url
          }
        }
        banos
        caracteristicasExternas
        caracteristicasInternas
        codigo
        dormitorios
        estado
        estrato
        fechaPublicacion
        pisos
        superficieConstruccion
        superficieTerreno
        superficiePrivada
        year
        tipo {
          name
        }
        estacionamientos
        
      }
    }
  }
`

